.div-body{
  margin-top: 60px;
}

.apartments-title {
  font-family: var(--main-font-family);
  font-size: 2.5em; /* Increase the font size */
  color: var(--secondary-color); /* Use a dark color */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Add some space below the title */
  margin-top: 0;
  padding: 10px; /* Add some padding */
  margin-left: 10%;
  border-bottom: 2px solid var(--card-background-color); /* Add a subtle bottom border */
  background-color: var(--card-background-color); /* Add a light background color */
  border-radius: 5px; /* Add rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  width: 80%;
}

.apartments-page {
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.apartment-card {
  max-width: 400px;
  border-radius: 15px !important;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.2s ease-in-out !important;
  margin: 10px;
  overflow: hidden;
}

.apartment-card:hover {
  transform: translateY(-5px);
}

.apartment-card .MuiCardContent-root {
  padding: 20px;
  background-color: var(--background-color);
}

.apartment-card .MuiTypography-root {
  color: var(--text-color);
}

@media (max-width: 768px) {
  .apartments-title {
    font-size: 1.7em; /* Increase the font size */
    margin-top: 0;
    padding: 10px 0 10px 0; /* Add some padding */
    margin-left: 5%;
    width: 90%;
  }
}

@media (max-width: 550px) {
  .apartments-title {
    font-size: 1.2em; /* Increase the font size */
    margin-top: 0;
    padding: 10px 0 10px 0; /* Add some padding */
    margin-left: 5%;
    width: 90%;
  }
}