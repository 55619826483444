.welcome-screen {
    height: 100vh;
    /*background-image: url('../../assets/images/WelcomeImage.jpg'); /* Update the path here */
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.welcome-div{
    width: 100%;
    z-index: 2;
}

.welcome-content {
    padding: 0 15% 0 15%;
    z-index: 2;
}

.welcome-content h1{
    font-size: 4rem;
    margin: 0;
    font-family: 'Allerta Stencil';
}

.welcome-content h3{
    font-size: 1.5rem;
    margin: 0;
}

.arrows-div{
    position: absolute;
    bottom: 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.arrow-text{
    font-size: large;
    margin-bottom: -15px;
    color: var(--navigation-secondary-color-blured);
}

.arrow1, .arrow2, .arrow3 {
    width: 50px;
    height: 50px;
    border-left: 6px solid var(--navigation-secondary-color-blured);
    border-bottom: 6px solid var(--navigation-secondary-color-blured);
    border-radius: 6px;
    transform: rotate(-45deg);
    margin-bottom: -22px; /* Adjust the value as needed */
}

.arrow3{
    margin-bottom: 25px;
}

.cards-title{
    text-align: center;
    font-weight: bold;
    font-family: var(--main-font-family);
    font-size: 2.5em; /* Increase the font size */
    color: var(--secondary-color); /* Use a dark color */
    text-align: center; /* Center the text */
    margin: 50px; /* Add some space below the title */
    padding: 10px; /* Add some padding */
    margin-left: 10%;
    border-bottom: 2px solid var(--card-background-color); /* Add a subtle bottom border */
    background-color: var(--card-background-color); /* Add a light background color */
    border-radius: 5px; /* Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    width: 80%;
}

.cardHandler{
    display: flex;
    /*justify-content: space-around;*/
    gap: 50px;
    flex-wrap: wrap;
    z-index: 2;
    width: 100%;
    margin-bottom: 50px;
}

.greyFilter{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4); /* Grey color with 50% transparency */
    filter: blur(1px); /* Adjust the blur amount */
    z-index: 1;
}

@media (max-width: 768px) {
    .welcome-content h1{
        font-size: 2rem;
        margin: 0;
        font-family: 'Allerta Stencil';
    }

    .welcome-content h3{
        font-size: 0.75rem;
    }

    .welcome-screen {
        height: 95vh;
        background-image: url('../../assets/images/WelcomeImage.jpg'); /* Update the path here */
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        overflow: hidden;
        position: relative;
    }

    .greyFilter{
        height: 100vh;
    }

    .cardHandler{
        justify-content: space-around;
    }

    #cards-title-ref{
        height: 60px;
    }

    .cards-title{
        font-size: 1.2em; /* Increase the font size */
        margin: 20px; /* Add some space below the title */
        padding: 10px; /* Add some padding */
        margin-left: 10%;
        border-bottom: 2px solid var(--card-background-color); /* Add a subtle bottom border */
        background-color: var(--card-background-color); /* Add a light background color */
        border-radius: 5px; /* Add rounded corners */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
        width: 80%;
    }
}