.apartment-details-div-body {
  font-family: "Roboto", sans-serif;
  margin-top: 60px;
  padding-top: 20px;
}

.header {
  margin-bottom: 20px;
}

.carousel-container{
  padding-bottom: 30px;
}

.tab-switcher-div{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.tab-switcher{
  height: 60px;
  width: 50%;
  max-width: 600px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--navigation-primary-color);
  font-size: large;
  list-style: none;
  cursor: pointer;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  background: #e6e9f057;
}

.tab-selected{
  background-color: var(--navigation-primary-color);
  color: var(--navigation-selected-color);
  text-decoration: underline;
  font-weight: bolder;
}

.tab-switcher li{
  height: 100%;
  width: 50%;
  font-family: var(--main-font-family);
  border: 2px solid var(--navigation-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-hidden{
  display: none !important;
}

.plan-div{
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.plan{
  width: 98vw;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  background: #e6e9f057;
  border-radius: 12px;
  box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.5);
  padding: 25px;
  margin-bottom: 20px;
}

.plan-img{
  position: relative;
  height: 85vw;
  max-height: 850px;
  /*transform: translateY(-25%) rotate(90deg);*/
  object-fit: contain;
}

.apartment-details-div {
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.apartment-details {
  background: #e6e9f057;
  border-radius: 12px;
  box-shadow: 5px 15px 20px rgba(0, 0, 0, 0.5);
  padding: 25px;
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: 900px;
  width: 100%;
}

.apartment-details h2 {
  font-size: 26px;
  color: #343a40;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
}

.apartment-details p {
  font-size: 18px;
  color: #495057;
  margin: 8px 0;
}

.apartment-details .status {
  font-weight: bold;
  color: #28a745; /* Green color for available */
}

.apartment-details .not-available {
  font-weight: bold;
  color: #dc3545; /* Red color for not available */
}

.no-apartments-found {
  text-align: center;
  font-size: 20px;
  color: #dc3545;
}

.learnMoreButton {
  border-radius: 20px !important;
  padding: 10px 20px !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  text-transform: none !important;
}

.btn-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .apartment-details {
    padding: 40px;
  }

  .plan{
    height: auto !important;
    flex-direction: column;
  }

  .plan-img{
    /*transform: rotate(0deg);*/
    height: 90% !important;
    width: 90% !important;
  }

  .tab-switcher{
    height: 45px;
    width: 70%;
  }
}