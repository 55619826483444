:root {
  --page-background-color: white;
  --primary-color: #19221A;
  --secondary-color: #DFFFFD;

  --navigation-primary-color: #19221A;
  --navigation-primary-color-blured: #19221aa7;
  --navigation-secondary-color: #DFFFFD;
  --navigation-secondary-color-blured: #DFFFFDA7;
  --navigation-selected-color: #9EE493;

  --card-background-color: #19221A;
  --card-primary-color: #DFFFFD;
  --card-secondary-color: #CDDDDD;
  
  --navigation-main-font-family: Courier;
  --main-font-family: Segoe;

  --background-color: #f7f7f7;
  --text-color: #777;
  --font-size: 16px;
  --font-color: #f4b541;

  --loading-background-collor: #608064d0;
  --loading-circle-collor: #19221ac9;
}
