@import "../../assets/comondata/CommonData.css";

.card{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 40% 15px 15px 15px!important;
    box-shadow: 8px 14px 18px rgba(0, 0, 0, 0.7) !important;
    width: 60%;
    background-color: var(--primary-color);
    color: var(--navigation-secondary-color);
    height: 50vh;

    opacity: 0;
    scale: 0.8;
    animation: fade-in linear forwards;
    animation-timeline: view();
    animation-range: 50px 36%;
}

@keyframes fade-in {
    to {
      opacity: 1;
      scale: 1;
    }
  }

.left{
    margin-left: 10%;
}

.right{
    margin-left: 32%;
}

.inverted{
    flex-direction: row-reverse;
    border-radius: 15px 40% 15px 15px!important;
}

.card-content{
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-left: 2%;
}

.card-title{
    font-family: Comic Sans MS !important;
    margin-bottom: 0;
}

.card-subtitle{
    margin-top: 0;
    margin-bottom: 10px;
    font-family: Comic Sans MS !important;
}

.card-description{
    margin-top: 0px;
    font-weight: normal;
    font-size: 16px;
}

.card-icon{
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image{
    width: 100%;
    height: 100%;
    border-radius: 40% 0 40% 0 ;
    background-size: cover;
    background-position: center;
}

.right .image{
    border-radius: 0 40% 0 40%;
}

/*.icon {
    color: var(--navigation-secondary-color);
    font-size: 45px;
}*/

.card-vertical-separator{
    height: 60%;
    width: 0px;
    background-color: var(--navigation-secondary-color);
}

.card-horizontal-separator{
    height: 2px;
    width: 60%;
    background-color: var(--navigation-secondary-color);
    margin-bottom: 3px;
}

@media (max-width: 768px) {
    .card{
        height: 30vh;
        width: 90%;
        margin-left: 0;
    }

    .card-title{
        font-family: Comic Sans MS !important;
        margin-top: 10px;
    }

    .card-content{
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 2%;
    }
    
    .card-icon{
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-description{
        margin-top: 5px;
        font-weight: normal;
        font-size: 12px;
    }
}