@import "../../assets/comondata/CommonData.css";

footer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--navigation-primary-color);
    position: relative;
    bottom: 0;
    height: 70px;
}

.footer-div{
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.button {
    text-decoration: none;
    color: white;
    background-color: #007bff; /* Blue color */
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s;
    font-size: 24px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #0056b3;
}

.whatsapp {
    background-color: #25D366; /* WhatsApp green color */
}

.whatsapp:hover {
    background-color: #1da851;
}

.email {
    background-color: #6c757d; /* Grey color */
}

.email:hover {
    background-color: #5a6268;
}

@media (max-width: 550px) {
    footer{
        height: 50px;
    }

    .footer-div{
        width: 60%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    
    .button {
        text-decoration: none;
        color: white;
        background-color: #007bff; /* Blue color */
        border-radius: 5px;
        display: inline-block;
        transition: background-color 0.3s;
        font-size: 24px;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button:hover {
        background-color: #0056b3;
    }
    
    .whatsapp {
        background-color: #25D366; /* WhatsApp green color */
    }
    
    .whatsapp:hover {
        background-color: #1da851;
    }
    
    .email {
        background-color: #6c757d; /* Grey color */
    }
    
    .email:hover {
        background-color: #5a6268;
    }
}