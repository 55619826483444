@import "../../assets/comondata/CommonData.css";

.customcard {
  max-width: 1000px;
  border-radius: 15px !important;
  box-shadow: 8px 14px 18px rgba(0, 0, 0, 0.7) !important;
  transition: transform 0.2s ease-in-out !important;
  margin: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.customcard:hover {
  transform: translateY(-5px);
}

.cardContentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: var(--card-background-color);
}

.cardMedia {
  object-fit: cover;
  width: 50% !important; /* Adjust as needed */
}

.cardContent {
  padding: 20px !important;
  background-color: var(--card-background-color);
  width: 50%; /* Adjust as needed */
}

.title {
  color: var(--card-primary-color);
  font-weight: bolder !important;
  border-bottom: 1px solid var(--card-primary-color);
  width: 70%;
}

.description {
  margin-bottom: 15px;
  color: var(--card-secondary-color) !important;
  padding-bottom: 15px;
}

.detail {
  margin-bottom: 5px;
  color: var(--card-secondary-color) !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.learnMoreButton {
  border-radius: 20px !important;
  padding: 10px 20px !important;
  background-color: var(--card-primary-color) !important;
  color: var(--card-background-color) !important;
  text-transform: none !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .cardContentContainer {
    flex-direction: column;
  }

  .cardMedia {
    width: 100% !important;
    height: 300px; /* Adjust as needed */
  }

  .cardContent {
    width: auto;
  }
}
