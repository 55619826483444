@import "../../assets/comondata/CommonData.css";

.apartment-card {
  max-width: 1000px;
  border-radius: 15px !important;
  box-shadow: 8px 14px 18px rgba(0, 0, 0, 0.7) !important;
  transition: transform 0.2s ease-in-out !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background-color) !important;
  width: 100%;
}

.cardTiltle{
  margin-bottom: 100px;
}

.apartment-card:hover {
  transform: translateY(-5px);
}

.cardContent {
  color: var(--card-primary-color);
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
}

.learnMoreButton {
  border-radius: 20px !important;
  padding: 10px 20px !important;
  background-color: var(--card-primary-color) !important;
  color: var(--card-background-color) !important;
  text-transform: none !important;
}
