@import "../../assets/comondata/CommonData.css";

.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--navigation-primary-color);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 4;
  height: 60px;
}

.transparentbg{
  background: transparent !important;
}

.nav-img {
  margin-left: 5%;
  padding: 10px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-right: 15%;
}

.nav-links li {
  position: relative;
  display: flex;
  align-items: center;
}

.nav-links li:not(:last-child)::after {
  content: "";
  display: block;
  width: 0.5px;
  height: 24px; /* Adjust height as needed */
  background-color: var(--navigation-secondary-color); /* Vertical bar color */
  margin-left: 10px; /* Space between text and bar */
}

.nav-links a,
.dropdown-content a,
.dropbtn {
  color: var(--navigation-secondary-color);
  text-decoration: none;
  font-family: var(--navigation-main-font-family) !important;
  font-size: 18px;
}

.nav-links .active > a,
.nav-links .active .dropdown > button {
  color: var(--navigation-selected-color);
  font-weight: bold;
  border-bottom: 2px solid var(--navigation-selected-color);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background: none;
  border: none;
  color: var(--navigation-secondary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropbtn-arrow {
  font-size: 12px;
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.dropdown-content a {
  padding: 12px 16px;
  display: block;
  position: relative;
}

.dropdown-content a:not(:last-child)::after {
  content: "";
  display: block;
  width: 100%;
  height: 0.5px; /* Thickness of the separator */
  background-color: var(--navigation-secondary-color); /* Color of the separator */
  position: absolute;
  bottom: 0;
  left: 0;
}

.dropdown-content a:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.dropdown:hover .dropdown-content {
  display: block;
}

#vertical-header {
  display: none;
}

#horizontal-header {
  display: block;
}

.vertical-header, .horizontal-header{
    z-index: 5;
}

@media (max-width: 550px) {
  #vertical-header {
    display: block;
  }

  #horizontal-header {
    display: none;
  }

  .horizontal-background{
    position: fixed;
    background: var(--navigation-primary-color-blured);
    backdrop-filter: blur(1px);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 4;
    transition: transform 0.3s ease-in-out;
  }
  
  .horizontal-background.hidden-background{
    transform: translateX(100%);
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 250px;
    background: var(--navigation-primary-color);
    display: flex;
    flex-direction: column;
    z-index: 5;
    height: 100%;
    right: 0;
    transition: transform 0.3s ease-in-out;
    justify-content: flex-start;
  }

  .navbar.hidden {
    transform: translateX(100%);
  }

  .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  .burger-div {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 4;
    background: var(--navigation-primary-color);
    height: 50px;
    align-items: center;
  }

  .burger-bar-div {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    z-index: 4;
  }

  .burger-bar {
    background-color: var(--navigation-secondary-color);
    height: 3px;
    width: 30px;
  }

  .close-btn {
    font-size: 24px;
    color: var(--navigation-secondary-color);
    font-weight: bold;
    text-align: center;
    margin: 10px;
    cursor: pointer;
  }

  .nav-img {
    margin-left: 5%;
    height: 30px;
    margin: 10px;
  }

  .nav-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 15%;
  }

  .nav-links li {
    display: flex;
    align-items: center;
  }

  .nav-links a,
  .dropdown-content a,
  .dropbtn {
    color: var(--navigation-secondary-color);
    text-decoration: none;
    font-family: var(--navigation-main-font-family) !important;
    font-size: 18px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropbtn {
    background: none;
    border: none;
    color: var(--navigation-secondary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .dropbtn-arrow {
    font-size: 12px;
    margin-left: 5px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 4;
  }

  .dropdown-content a {
    padding: 12px 16px;
    display: block;
  }

  .dropdown-content a:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #333;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .dropdown-content a:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .nav-links li:not(:last-child)::after {
    display: none;
  }

  .horizontal-separator{
    display: flex;
    width: 60%;
    height: 0.5px;
    background-color: var(--navigation-secondary-color);
    margin-left: 20%;
  }
}
