.welcome-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Fixed height */
  margin: auto;
  overflow: hidden;
}

.welcome-carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%; /* Ensure it takes full height */
}

.welcome-carousel-item {
  min-width: 100%;
  height: 100%; /* Ensure it takes full height */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.welcome-carousel-item.active {
  opacity: 1;
  position: relative;
  transform: scale(1);
}

.welcome-carousel img {
  width: 100%;
  height: 100%; /* Ensure it takes full height */
  object-fit: cover; /* Ensures the image covers the element without distortion */
  display: block;
}

.welcome-carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 35px;
  cursor: pointer;
  padding: 10px;
  z-index: 3;
  height: 100px;
}

.welcome-carousel-control.prev {
  left: 10px;
}

.welcome-carousel-control.next {
  right: 10px;
}

.welcome-carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.welcome-carousel-indicator {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.welcome-carousel-indicator.active {
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .welcome-carousel-control {
    font-size: 18px;
    height: 50px;
  }
}