@import "../../assets/comondata/CommonData.css";

.div-body{
  margin-top: 60px;
  background-color: var(--page-background-color);
  height: 100%;
}

.projects-page {
  padding-top: 20px;
  font-family: "Roboto", sans-serif;
}

.project-list {
  padding: 20px;
}

.projects {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.projects > div {
  flex: 1 1 600px; /* Each card will take up at least 600px and grow if there's space */
}

.project-title {
  font-family: var(--main-font-family);
  font-size: 2.5em; /* Increase the font size */
  color: var(--secondary-color); /* Use a dark color */
  text-align: center; /* Center the text */
  margin-bottom: 20px; /* Add some space below the title */
  margin-top: 0;
  padding: 10px; /* Add some padding */
  margin-left: 10%;
  border-bottom: 2px solid var(--card-background-color); /* Add a subtle bottom border */
  background-color: var(--card-background-color); /* Add a light background color */
  border-radius: 5px; /* Add rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  width: 80%;
}

@media (max-width: 768px) {
  .project-title {
    font-size: 1.7em; /* Increase the font size */
    margin-bottom: 0px; /* Add some space below the title */
    margin-top: 0;
    padding: 10px 0 10px 0; /* Add some padding */
    margin-left: 5%;
    width: 90%;
  }
}

@media (max-width: 550px) {
  .project-title {
    font-size: 1.2em; /* Increase the font size */
    margin-bottom: 0px; /* Add some space below the title */
    margin-top: 0;
    padding: 10px 0 10px 0; /* Add some padding */
    margin-left: 5%;
    width: 90%;
  }
}