@import "../../assets/comondata/CommonData.css";

.div-body {
  margin-top: 60px;
}

/*.about-us-footer footer {
  position: absolute;
  bottom: 0;
}*/

.about-us {
  padding: 20px;
}

.about-us-content{
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: center;
  justify-content: center;
}

.about-us-main-content-title{
  margin-bottom: 10px;
  margin-top: 50px;
  border-bottom: 3px solid var(--primary-color);
  width: 50%;
}

.about-us-content-title{
  margin-bottom: 10px;
  margin-top: 50px;
  border-bottom: 3px solid var(--primary-color);
  width: 40%;
  text-align: center;
  margin-left: 30%;
}

.about-us-vertical-separator{
  height: 300px;
  width: 3px;
  background-color: var(--primary-color);
}

.about-us-horizontal-separator{
  width: 50%;
  height: 2px;
  background-color: var(--primary-color);
}

.about-us-main-content{
  margin-left: 20px;
  width: 50%;
}

.missions{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;;
}

.mission-item{
  width: 300px;
  border: 1px solid var(--primary-color);
  box-shadow: 8px 14px 18px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 10px;
}

.mission-title{
  width: 70%;
  border-bottom: 1px solid var(--primary-color);
  font-weight: bold;
  margin-top: 0px;
}

.gold-star{
  color: goldenrod;
  font-size: larger;
}

.tick{
   font-size: larger;
   color: var(--primary-color);
}

.light-bulb{
  font-size: larger;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .about-us-content{
    flex-direction: column;
    height: auto;
  }

  .about-us-content-title{
    margin-bottom: 10px;
    margin-top: 50px;
    border-bottom: 3px solid var(--primary-color);
    width: 50%;
    text-align: left;
    margin-left: 10px;
  }  

  .about-us-main-content{
    margin-left: 20px;
    width: 90%;
  }

  .about-us-vertical-separator{
    display: none;
  }

  .about-us-footer footer {
    position: relative;
    bottom: 0;
  }
}