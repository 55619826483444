.loading-background {
  position: fixed;
  background: var(--loading-background-collor);
  backdrop-filter: blur(1px);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rotatorbackground {
  border: 10px solid #19221a33;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.rotatorbox {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100px;
  width: 100px;
  animation: rotateAndcolor 2s linear infinite;
}

.dot {
  place-self: center;
  background-color: #19221aa7;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.rotatorbox:nth-child(2) {
  animation-delay: 0.2s;
}

.rotatorbox:nth-child(3) {
  animation-delay: 0.4s;
}

.rotatorbox:nth-child(4) {
  animation-delay: 0.6s;
}

.rotatorbox:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes rotateAndcolor {
  0% {
    border-color: #dffffda7;
    transform: rotate(0deg);
  }
  20% {
    border-color: #dffffda7;
    transform: rotate(120deg);
  }
  80% {
    border-color: #dffffda7;
    transform: rotate(240deg);
  }
  100% {
    border-color: #dffffda7;
    transform: rotate(360deg);
  }
}

.loading-text{
    font-family: var(--main-font-family);
    font-size: large;
    color: var(--navigation-secondary-color);
}

.loading-done{
    display: none;
}
