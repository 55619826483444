.my-data-container {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row;
}

.col1 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.col2 {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.col-name {
  width: 100%;
  text-align: left;
  font-weight: bolder;
}

.col-data{
    width: 100%;
    text-align: center;
}

.col-separator::after{
    content: "";
    display: block;
    width: 0.5px;
    height: 100%; /* Adjust height as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Vertical bar color */
    margin-right: 20px; /* Space between text and bar */
}

@media only screen and (max-width: 768px) {
  .my-data-container {
    flex-direction: column;
  }

  .col-separator::after{
    content: "";
    display: none;
    width: 0.5px;
    height: 100%; /* Adjust height as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Vertical bar color */
    margin-right: 20px; /* Space between text and bar */
}
}
